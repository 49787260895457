import { Controller } from 'stimulus';
import MarkdownIt from 'markdown-it'

export default class extends Controller {
  connect() {
    const md = new MarkdownIt();
    const html = md.render(this.element.innerHTML);
    this.element.innerHTML = html;
  }
}
