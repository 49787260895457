import { Controller } from 'stimulus';
import { initSelect2 } from './../packs/components/init_select2';
import { initFlatpickr } from './../packs/components/flatpickr';

export default class extends Controller {
  static targets = ['filterArrow', 'collapsableFilters'];

  connect() {
    initSelect2('.select2', { width: '100%' });
    initFlatpickr('.date', {
      altInput: true,
      enableTime: true
    });
  }

  filterTalks(event) {
    this.element.submit();
  }

  rotateArrow() {
    if (!this.collapsableFiltersTarget.classList.contains('collapsing')) {
      this.filterArrowTarget.classList.toggle('rotated-arrow');
    }
  }
}
