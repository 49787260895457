import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    const endDate = new Date('Dec 26, 2019 12:00:00');
    const interval = setInterval(() => {
      const now = new Date();
      const diff = endDate - now;
      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((diff % (1000 * 60)) / 1000);
      this.element.innerHTML = `${days}d ${hours}h ${minutes}m ${seconds}s`;
      if (diff < 0) {
        clearInterval(interval);
        this.element.innerHTML = 'Fundraiser is over!';
      }
    }, 1000)
  }
}
