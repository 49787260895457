import { Controller } from 'stimulus';
import { initSelect2 } from './../packs/components/init_select2';

export default class extends Controller {
  updateList(event) {
    const data = event.detail[0];
    document.getElementById('talk-form').innerHTML = data.body.innerHTML;
    initSelect2('.select2', { width: '100%' });
  }
}
