import { Controller } from 'stimulus';
import { initCarousel } from './../packs/components/slick_carousel';

export default class extends Controller {
  connect() {
    initCarousel('carousel', {
    infinite: true,
    mobileFirst: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: true,
    lazyLoad: 'ondemand',
    prevArrow: '<i class="fas fa-angle-left nav-arrows" aria-hidden="false"></i>',
    nextArrow: '<i class="fas fa-angle-right nav-arrows" aria-hidden="false"></i>',
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4
        }
      }]
    });
  }
}
