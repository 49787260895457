import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['magnifier', 'img'];
  magnify(event) {
    this.imgTarget.src = event.currentTarget.src;
    this.magnifierTarget.classList.add('d-flex');
    this.magnifierTarget.classList.remove('d-none');
  }

  close() {
    this.magnifierTarget.classList.remove('d-flex');
    this.magnifierTarget.classList.add('d-none');
  }
}
