import Layzr from 'layzr.js'

const initLayzr = () => {
  const instance = Layzr({
    normal: 'data-normal',
    threshold: 0
  });

  instance
      .update()           // track initial elements
      .check()            // check initial elements
      .handlers(true)
};

export { initLayzr };
