import { Controller } from 'stimulus';
import Typed from 'typed.js';

export default class extends Controller {
  connect() {
    const options = {
      strings: ['A Revolution.',
      'A Better Lebanon.',
      'A Prosperous Lebanon.',
      'An Inclusive Lebanon.',
      'A Clean Lebanon.',
      'A Better Lebanon.',
      'A Sustainable Lebanon.',
      'A Happy Lebanon.',
      'A Better Lebanon.',
      'A United Lebanon.',
      'A Just Lebanon.',
      'A Citizen-led Lebanon.',
      'A Democratic Lebanon.',
      'An Equal Lebanon.',
      'An Honest Lebanon.',
      'An Ethical Lebanon.',
      'A Moral Lebanon.'
      ],
      typeSpeed: 40,
      loop: true,
      loopCount: Infinity
    };
    new Typed(this.element, options);
  }
}
