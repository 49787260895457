require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("trix");
require("@rails/actiontext");

import "bootstrap";
import "@mdi/font/css/materialdesignicons.css";
import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";
import "slick-carousel/slick/slick.scss";
import "flatpickr/dist/flatpickr.min.css";
import "slick-carousel/slick/slick.scss";
import "select2/dist/css/select2.css";
import 'aos/dist/aos.css';
import AOS from 'aos';
import { initLayzr } from './components/init_layzr';

document.addEventListener('turbolinks:load', () => {
  const application = Application.start();
  const context = require.context("./../controllers", true, /\.js$/);
  application.load(definitionsFromContext(context));

  AOS.init({ duration: 1500 });
  initLayzr();
});
