import { Controller } from 'stimulus';
import { initFlatpickr } from './../packs/components/flatpickr';

export default class extends Controller {
  static targets = ['timelineArrow', 'collapsableTimeline', 'week', 'daysWrapper', 'weekCheckpoint'];
  flatpickr() {
    initFlatpickr('.date', {
      altInput: true
    });
  }

  rotateArrow() {
    if (!this.collapsableTimelineTarget.classList.contains('collapsing')) {
      this.timelineArrowTarget.classList.toggle('rotated-arrow');
    }
  }

  toggleWeekColor(event) {
    if (!this.daysWrapperTarget.classList.contains('collapsing')) {
      event.currentTarget.classList.toggle('active');
      event.currentTarget.parentElement.querySelector('.week-checkpoint').classList.toggle('active-checkpoint');
    }
  }
}
